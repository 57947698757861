.content_area_detail {
  overflow-y: hidden;
  border-radius: 6px;
  height: calc(100vh - 110px);
  display: flex;
  flex-direction: row;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.09), 0 2px 5px 0 rgba(0, 0, 0, 0.12);
}

.left_side_datail {
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: var(--branco);
}

.left_side_datail_top {
  position: relative;
  width: 100%;
  height: 40px;
  background: var(--azul-600);
  color: var(--branco);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  border-top-left-radius: 6px;
}

.left_side_detail_bottom {
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 6px;
  overflow-y: auto;
  border-right: 3px solid var(--azul-600);
  background-color: var(--branco);
  border-bottom-left-radius: 6px;
}

.right_side_datail {
  flex: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.right_side_datail_top {
  position: relative;
  width: 100%;
  height: 40px;
  background: var(--azul-600);
  color: var(--branco);
  padding-left: 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: 6px;
}

.right_side_datail_center {
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: var(--branco);
}

.chat_box_datail {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
  overflow-y: auto;
  position: relative;
  padding: 6px 21px;
}

.right_side_detail_bottom {
  position: relative;
  width: 100%;
  background: var(--azul-200);
  display: flex;
  flex-direction: column;
  border-bottom-right-radius: 6px;
}

.header_icons {
  height: 100%;
  width: 250px;
  margin-right: 9px;
}

.icon-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  color: var(--branco);
  border: 1px solid #ccc;
  margin-right: 9px;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.5s, border-color 0.3s;
}

.icon-button span {
  margin-left: 8px;
}

.icon-button:hover {
  background-color: var(--erro);
  border-color: var(--erro);
  color: var(--azul-100);
}

.icon-button-aprovar {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: var(--erro);
  border-color: var(--erro);
  color: var(--azul-100);
  margin-right: 9px;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.5s, border-color 0.3s;
}

.icon-button-aprovar span {
  margin-left: 8px;
}

.icon-button-aprovar:hover {
  filter: brightness(1.2);
}

/* Timeline */
.timeline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 20px 0;
  padding-right: 15px;
  border-right: 3px solid var(--azul-600);
  border-bottom: 3px solid var(--azul-600);
}

.timeline-step {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  flex: 1;
}

.timeline-description {
  font-size: 11px;
  text-align: left;
  align-content: center;
  margin-left: 25px;
  transform: rotate(-60deg);
  padding-left: 2px;
  height: 30px;
  width: 30px;
  white-space: pre-wrap; /* Mantém as quebras de linha */
}

.timeline-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ccc;
  z-index: 1;
}

.timeline-circle.active {
  background-color: var(--azul-600);
}

.timeline-circle.actived {
  background-color: var(--azul-300);
}

.timeline-line-before {
  position: absolute;
  width: calc(50% - 10px);
  height: 2px;
  background-color: #ccc;
  top: 39px;
  left: 0;
  z-index: -1;
}

.timeline-line-before.active {
  background-color: var(--azul-300);
}

.timeline-line-after {
  position: absolute;
  width: calc(50% - 10px);
  height: 2px;
  background-color: #ccc;
  top: 39px;
  right: 0;
  z-index: -1;
}

.timeline-line-after.active {
  background-color: var(--azul-300);
}

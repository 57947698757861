.sidebar {
  height: 100%;
  width: 220px;
  margin: 0;
  padding: 0;
  background-color: var(--marinho-500);
  position: fixed;
  overflow: none;
  transition: width 0.2s ease;
}

.sidebar div .imgem_menu {
  height: 100%;
  object-fit: cover;
  filter: drop-shadow(2px 3px 6px var(--preto));
  -webkit-filter: drop-shadow(2px 3px 6px var(--preto));
}

.sidebar a {
  z-index: 9;
  display: block;
  padding: 16px;
  display: flex;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
  flex-direction: row;
  align-items: center;
  transition: ease-in-out 0.4s;
}

.sidebar a:hover {
  background-color: var(--marinho-700);
  color: var(--branco);
}

.sidebar a svg {
  z-index: 9;
  margin-right: 0.5rem;
}

.content {
  margin-left: 220px;
  padding: 1px 16px;
  transition: margin-left 0.3s ease;
}
.sidebar .logo-customax {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 110px;
  cursor: pointer;
}

.sidebar .logo-customax img {
  width: 80%;
}

/* Menu Rodapé infos */
.sidebar .divisor {
  position: absolute;
  justify-content: center;
  left: 15%;
  bottom: 50px;
  width: 70%;
  height: 30px;
}

.sidebar .divisor img {
  position: absolute;
  height: 100%;
  width: 100%;
}

.sidebar .social-media-icons {
  position: absolute;
  bottom: 75px;
  z-index: 1;
  width: 80%;
  height: 60px;
  left: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: ease-in-out 0.4s;
}

.sidebar .social-media-icons a {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 6px;
}

.sidebar .social-media-icons a svg {
  margin-right: 0;
}

.sidebar .additional-links {
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 80px;
}

.sidebar .additional-links a {
  font-size: small;
  padding: 2px 16px;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sidebar .additional-links a:hover {
  background-color: var(--marinho-500);
  color: var(--branco);
}

@media screen and (max-width: 880px) {
  .sidebar {
    width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: width 0.2s ease;
  }

  div.content {
    margin-left: 70px;
    transition: margin-left 0.2s ease;
  }

  .sidebar a {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sidebar a svg {
    margin-right: 0;
  }

  .sidebar a span,
  .sidebar div,
  .sidebar .social-media-icons,
  .sidebar .divisor,
  .sidebar .additional-links {
    display: none;
  }
}

@media screen and (max-height: 520px) {
  .sidebar .social-media-icons {
    display: none;
  }
}

@media screen and (max-height: 480px) {
  .sidebar .divisor {
    display: none;
  }
}

@media screen and (max-height: 450px) {
  .sidebar .additional-links {
    display: none;
  }
}

.filtro {
  background-color: var(--azul-600);
  padding: 0 9px;
  padding-bottom: 12px;
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.titulo {
  color: var(--branco);
}

.right_footer {
  min-width: 470px;
  position: relative;
  width: 100%;
  background: var(--azul-600);
  padding: 9px 9px;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom-right-radius: 6px;
}

.right_footer span {
  cursor: pointer;
  margin: 9px 3px;
  font-size: 1.8em;
  color: var(--branco);
}

#acaoInput {
  font-family: "Segoe UI", "Helvetica Neue", "Helvetica", "Lucida Grande", Arial;
  position: relative;
  width: 100%;
  height: 46px;
  min-height: 46px;
  max-height: 150px;
  margin: 0 12px;
  padding: 10px 15px;
  resize: none;
  border: none;
  outline: none;
  border-radius: 6px;
  font-size: 1rem;
}

/* body {
  background-color: #d4d9ed;
} */

.container_principal {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.container_error {
  position: absolute;
  width: 100%;
  height: 300px;
  top: 50%;
  margin-top: -150px;
}

.container_error > h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 80px;
  color: #339fd7;
  position: relative;
  left: -100%;
  transition: all 0.5s;
}

.container_error > p {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 24px;
  letter-spacing: 5px;
  color: #9294ae;
  position: relative;
  left: 100%;
  transition: all 0.5s;
  transition-delay: 0.5s;
  -webkit-transition: all 0.5s;
  -webkit-transition-delay: 0.5s;
}

.container_error > button {
  margin-top: 30px;
  margin-left: 5%;
  font-family: 'Lato', sans-serif;
  background-color: #3498db;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.container_error > button:hover {
  background-color: #2980b9;
}

.aura_1 {
  position: absolute;
  width: 300px;
  height: 120%;
  top: 25px;
  right: -340px;
  z-index: 4;
  background-color: #8a65df;
  box-shadow: 0px 0px 60px 20px rgba(137, 100, 222, 0.5);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.aura_2 {
  position: absolute;
  width: 100%;
  height: 300px;
  right: -10%;
  bottom: -301px;
  z-index: 5;
  background-color: #8b65e4;
  box-shadow: 0px 0px 60px 10px rgba(131, 95, 214, 0.5),
    0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.container_error_active > h1 {
  left: 5%;
  z-index: 6;
}
.container_error_active > p {
  left: 5%;
  z-index: 6;
}

.aura_2 {
  animation-name: animation_error_2;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform: rotate(-20deg);
}
.aura_1 {
  transform: rotate(20deg);
  right: -170px;
  animation-name: animation_error_1;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@-webkit-keyframes animation_error_1 {
  from {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  to {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
}
@-o-keyframes animation_error_1 {
  from {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  to {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
}
@-moz-keyframes animation_error_1 {
  from {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  to {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
}
@keyframes animation_error_1 {
  from {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  to {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
}

@-webkit-keyframes animation_error_2 {
  from {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  to {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}
@-o-keyframes animation_error_2 {
  from {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  to {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}

@-moz-keyframes animation_error_2 {
  from {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  to {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}
@keyframes animation_error_2 {
  from {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  to {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}

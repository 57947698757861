.content_area {
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  background-color: var(--branco);
  height: calc(100vh - 120px);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.09), 0 2px 5px 0 rgba(0, 0, 0, 0.12);
}

.content_area form {
  margin: 0;
  padding: 15px;
  width: 100%;
  overflow: auto;
}

.content_area form textarea {
  resize: none;
  height: 80px;
  width: 100%;
  border-radius: 6px;
}

.area {
  margin-bottom: 6px;
  margin: 0 12px;
  display: flex;
  flex-direction: column;
}

.area label {
  font-size: 1rem;
  margin-top: 12px;
}

.checkbox-container {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: baseline;
}

.checkbox-label {
  margin-left: 6px;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.btn {
  height: 100%;
  margin-right: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.politica_privacidade {
  height: 45px;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
}

.campo-com-icone {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-right: 3px;
}

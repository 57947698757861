.custom-red-cell {
  background-color: red;
  color: var(--branco);
  padding: 8px; /* Ajuste o padding conforme necessário */
  height: 100%; /* Garante que o fundo cubra toda a célula */
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px; /* Opcional: arredondamento dos cantos */
}

.custom-yellow-cell {
  background-color: yellow;
  color: black;
  padding: 8px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.custom-input-footer {
  position: absolute;
  bottom: 24px;
  left: 245px;
  padding: 5px;
  width: 150px;
  transition: 0.2s ease;
}

.custom-csvlink-footer {
  position: absolute;
  bottom: 32px;
  left: 385px;
  width: 40px;
  transition: 0.2s ease;
  color: var(--branco);
}

.csv-icon {
  color: white; /* Define a cor do ícone como branco */
}

@media screen and (max-width: 880px) {
  .custom-input-footer {
    left: 95px;
  }
  .custom-csvlink-footer {
    left: 235px;
  }
}

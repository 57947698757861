.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em 0;
  border-radius: 6px;
  background: var(--branco);
  padding: 1em;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.09), 0 2px 5px 0 rgba(0, 0, 0, 0.12);
}

.title .title_info {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
}

.title .title_info span {
  margin-left: 0.6rem;
  font-size: 1.2rem;
}

.title img {
  display: flex;
  align-self: flex-end;
  cursor: pointer;
}

.title .menu {
  position: absolute;
  width: 180px;
  left: calc(100% - 210px);
  top: 80px;
  z-index: 50;
  background: var(--azul-100);
  border-radius: 6px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.21);
  overflow: hidden;
  padding: 9px 12px;
  z-index: 99;
}

.title .menu .menu_item {
  list-style: none;
  display: flex;
  align-items: center;
  height: 36px;
  width: 100%;
  cursor: pointer;
  border-radius: 9px;
  padding-left: 9px;
  transition: 0.3s;
  color: inherit;
}

.title .menu .menu_item:hover {
  background-color: var(--azul-200);
}

.title .menu .menu_text {
  font-size: 16px;
  margin-left: 6px;
}

@media screen and (max-width: 880px) {
  .title .menu {
    top: 130px;
  }
}

@media screen and (max-width: 560px) {
  .title .menu {
    top: 235px;
  }
}

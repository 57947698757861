.action {
  position: relative;
  display: flex;
  width: 100%;
  margin: 5px 0;
}
.action p {
  position: relative;
  right: 0;
  text-align: left;
  max-width: 85%;
  padding: 12px 12px 6px 12px;
  background: var(--azul-200);
  border-radius: 10px;
  word-wrap: break-word;
  color: #111;
}

.action p svg {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  color: #51585c;
  margin-top: 3px;
}

.action p svg:hover {
  color: #000;
}

.action p::before {
  content: '';
  position: absolute;
  top: 0;
  right: -12px;
  width: 20px;
  height: 20px;
  background: linear-gradient(
    135deg,
    var(--azul-200) 0%,
    var(--azul-200) 50%,
    transparent 50%,
    transparent
  );
}

.action p span {
  display: block;
  margin-top: 6px;
  font-size: 0.8em;
  opacity: 0.5;
  text-align: right;
}

.cli_action {
  justify-content: flex-end;
}

.cmx_action {
  justify-content: flex-start;
  text-overflow: ellipsis;
}
.cmx_action p {
  background: var(--azul-400);
}
.cmx_action p span {
  text-align: left;
}

.cmx_action p span svg {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  color: #51585c;

  margin-top: 3px;
}

.action.cmx_action p::before {
  content: '';
  position: absolute;
  top: 0;
  left: -12px;
  width: 20px;
  height: 20px;
  background: linear-gradient(
    225deg,
    var(--azul-400) 0%,
    var(--azul-400) 50%,
    transparent 50%,
    transparent
  );
}

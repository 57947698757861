.checkbox_item {
  height: 24px;
  background: #fff;
  border-radius: 6px;
  padding: 9px 12px;
  display: flex;
  align-items: center;
  justify-content: baseline;
  padding: 3px 0px;
  margin-right: 12px;
  cursor: pointer;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    box-shadow: 0 0 6px 1px var(--azul-400);
    background-color: var(--azul-100);
    transition: background-color 0.3s ease-in-out;
  }

  &:focus {
    border-color: var(--azul-500);
    box-shadow: 0 0 6px 1px var(--azul-500);
    outline: none;
  }

  .checkbox_box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    border-radius: 3px;
    margin-right: 6px;
    border: 1.5px solid var(--azul-500);
    transition: all 0.3s ease-in-out;
  }

  .checkbox_icon {
    font-size: 12px;
    color: var(--azul-100);
    transform: scale(0);
    transition: all 0.5s ease-in-out;
  }

  &.item_checked {
    .checkbox_icon {
      transform: scale(1);
    }
    .checkbox_box {
      background-color: var(--azul-500);
    }
  }

  .checkbox_item_text {
    font-size: 16px;
  }
}

.noticia {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: -5px 5px 15px 2px rgba(0, 0, 0, 0.3),
    0 5px 15px 2px rgba(0, 0, 0, 0.4);
  margin-bottom: 9px;
  margin-left: 21px;
  margin-right: 21px;
  padding: 9px;
}

.noticia .titulo {
  color: #566a7f;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 9px;
  font-size: 16px;
  text-align: left;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
}
.noticia .noticia-texto {
  position: relative;
  color: #566a7f;
  font-size: 14px;
  margin-bottom: 6px;
  word-wrap: break-word;
}

.noticia .data {
  color: #566a7f;
  font-size: 12px;
  margin-top: 3px;
  text-align: right;
}

.input {
  display: block;
  width: 100%;
  height: 26px;
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 400;
  color: var(--marinho-300);
  background: var(--branco);
  border: 1px solid var(--azul-300);
  background-clip: padding-box;
  border-radius: 6px;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input:hover:not(.desabilitado) {
  border-color: var(--azul-400);
  box-shadow: 0 0 6px 1px var(--azul-400);
}

.input:focus {
  border-color: var(--azul-500);
  box-shadow: 0 0 6px 1px var(--azul-500);
}

.desabilitado {
  filter: brightness(96%);
  /* background-color: #f1f1f1; */
}

.errorMessage {
  font-size: 11px;
  color: var(--erro);
}

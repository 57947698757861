.container_login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-height: 100vh;
  background: linear-gradient(to bottom, var(--azul-100), var(--marinho-100));
}

.box {
  position: relative;
}

.box .square {
  position: absolute;
  backdrop-filter: blur(5px);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 9px;
  animation: animate 10s linear infinite;
  animation-delay: calc(-1s * var(--var1));
}

@keyframes animate {
  0%,
  100% {
    transform: translateY(-40px);
  }
  50% {
    transform: translateY(40px);
  }
}

.box .square:nth-child(1) {
  top: -50px;
  right: -60px;
  width: 100px;
  height: 100px;
}

.box .square:nth-child(2) {
  top: 150px;
  left: -100px;
  width: 120px;
  height: 120px;
  z-index: 2;
}

.box .square:nth-child(3) {
  bottom: 50px;
  right: -60px;
  width: 80px;
  height: 80px;
  z-index: 2;
}

.box .square:nth-child(4) {
  bottom: -80px;
  left: 100px;
  width: 50px;
  height: 50px;
}

.box .square:nth-child(5) {
  top: -80px;
  left: 140px;
  width: 60px;
  height: 60px;
}

.container_login .color_login {
  position: absolute;
  filter: blur(180px);
}

.container_login .color_login:nth-child(1) {
  top: -350px;
  width: 60%;
  height: 600px;
  background: var(--roxo-500);
}

.container_login .color_login:nth-child(2) {
  bottom: -150px;
  left: 100px;
  width: 500px;
  height: 500px;
  background: var(--azul-500);
}

.container_login .color_login:nth-child(3) {
  bottom: 50px;
  right: 100px;
  width: 300px;
  height: 300px;
  background: var(--marinho-500);
}

.box {
  position: relative;
}

.login_area {
  position: relative;
  width: 420px;
  min-height: 420px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.form {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 40px;
}

.form h2 {
  position: relative;
  color: var(--marinho-500);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 40px;
}

.form h2::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 80px;
  height: 4px;
  background: var(--marinho-500);
  border-radius: 2px;
}

.form .input_box {
  width: 100%;
  margin-top: 20px;
}

.form .input_box input {
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 35px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 16px;
  letter-spacing: 1px;
  color: var(--marinho-500);
  box-shadow: 0 5px rgba(0, 0, 0, 0.05);
}

.form .input_box input:hover {
  background: rgba(240, 240, 240, 0.2);
  border: 1px solid rgba(240, 240, 240, 0.5);
  border-right: 1px solid rgba(240, 255, 255, 0.2);
  border-bottom: 1px solid rgba(240, 240, 240, 0.2);
  box-shadow: 0 5px rgba(10, 10, 10, 0.05);
}

.form .input_box input::placeholder {
  color: #807c83;
}

.form .input_box input[type="submit"] {
  cursor: pointer;
  background: #fff;
  color: var(--marinho-500);

  margin-bottom: 21px;
  font-weight: 600;
}

.form .input_box input[type="submit"]:hover {
  cursor: pointer;
  background: #f7f2f2;
  color: var(--marinho-500);

  transform: scale(1.02);
  transition: transform 0.3s ease-in-out;
}
.form .input_box input:focus {
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease-in-out;
}

.form .input_box input[type="submit"]:focus {
  background: #fff;
  color: var(--marinho-500);
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.forget {
  color: var(--marinho-500);
  letter-spacing: 1px;
}

.forget a {
  color: var(--marinho-500);
  letter-spacing: 1px;
  font-weight: 600;
}

.forget a:hover {
  color: #7e14cf;
  transition: transform 0.3s ease-in-out;
}

.button {
  height: 30px;
  width: 120px;
  max-width: 600px;
  font-size: 12px;
  background-color: var(--azul-500);

  border: 0;
  padding: 0.4rem;
  color: var(--cmx-branco);
  border-radius: 6px;
  transition: filter 0.2s;
  outline: none;

  &[disabled] {
    cursor: not-allowed;
    svg {
      animation: animate 2s infinite;
    }
  }

  &:hover {
    background-color: var(--azul-600);
    box-shadow: 0 0 6px 1px var(--azul-600);
  }

  &:focus {
    outline: none;
    background-color: var(--azul-700);
    box-shadow: 0 0 6px 1px var(--azul-700);
  }
}

.buttonText {
  color: var(--azul-100);
}

@keyframes animate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

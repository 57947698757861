.content_home {
  /* border-radius: 6px; */
  display: flex;
  flex-direction: row;
  overflow: auto;
  /* background: #fff; */
  /* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.09), 0 2px 5px 0 rgba(0, 0, 0, 0.12); */
}

.content_home .right_side {
  /* background: #fff; */
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 50%;
  height: calc(100vh - 120px);
  /* border-right: 1px solid var(--azul-500); */
}

.content_home .left_side {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: calc(100vh - 120px);
}

.titulo_home {
  color: #566a7f;
  position: relative;
  margin-left: 21px;
  margin-right: 21px;
  align-items: center;
  width: 50%;
  padding-bottom: 9px;
  font-size: 18px;
  text-align: left;
  border: 0;
}

.warning_area {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  font-size: medium;
  font-weight: 600;
  color: var(--erro);
  gap: 12px;
  cursor: pointer;
  border-radius: 6px;
  margin: 21px;
  margin-top: 33px;
  width: 90%;
  padding: 0 9px;
  box-shadow: -5px 5px 15px 2px rgba(0, 0, 0, 0.3),
    0 5px 15px 2px rgba(0, 0, 0, 0.4);
}

.grafico_area {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 6px;
  margin-left: 21px;
  margin-right: 21px;
  margin-bottom: 21px;
  width: 90%;
  min-height: 350px;
  padding: 9px;
  box-shadow: -5px 5px 15px 2px rgba(0, 0, 0, 0.3),
    0 5px 15px 2px rgba(0, 0, 0, 0.4);
}

.input {
  display: block;
  width: 100%;
  height: 26px;
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 400;
  color: var(--marinho-300);
  background: var(--branco);
  border: 1px solid var(--azul-300);
  background-clip: padding-box;
  border-radius: 6px;
  outline: none;
  resize: none; // para textArea
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    border-color: var(--azul-400);
    box-shadow: 0 0 6px 1px var(--azul-400);
  }

  &:focus {
    border-color: var(--azul-500);
    box-shadow: 0 0 6px 1px var(--azul-500);
  }

  option {
    color: var(--marinho-400);
    background-color: var(--branco);
    padding: 8px;
    border: 1px solid var(--roxo-300);

    &:hover {
      color: var(--erro);
    }
  }

  &:hover .options {
    display: block;
  }
}

.desabilitado {
  filter: brightness(96%);
  // color: var(--erro);
}

.errorMessage {
  font-size: 11px;
  color: #bd2f2f;
}

.dropDown-multiselect {
  position: relative;
  width: 100%;
  height: 26px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: center;
}

.btn-select {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
  border-radius: 6px;
  cursor: pointer;
  background: var(--branco);
  border: 1px solid var(--azul-300);
}

.btn-select:hover {
  border-color: var(--azul-400);
  box-shadow: 0 0 6px 1px var(--azul-400);
}

.btn-select .btn-text {
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btn-select .arrow-down {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  width: 21px;
  border-radius: 50%;
  /* background: var(--azul-600); */
  color: var(--azul-600);
  transition: 0.3s;
}

.btn-select .arrow-down svg {
  margin-top: 1px;
}

.btn-select.active-multiselect .arrow-down {
  transform: rotate(-180deg);
}

.option-multiselect {
  position: absolute;
  top: 30px;
  width: 95%;
  z-index: 50;
  background: var(--branco);
  border-radius: 6px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.21);
  overflow: hidden;
  padding: 9px 12px;
  display: none;
}
.btn-select.active-multiselect ~ .option-multiselect {
  display: block;
}

.option-multiselect .checkbox-item {
  list-style: none;
  display: flex;
  align-items: center;
  height: 26px;
  width: 100%;
  margin-bottom: 6px;
  cursor: pointer;
  border-radius: 9px;
  padding-left: 9px;
  transition: 0.3s;
}

.option-multiselect .checkbox-item:hover {
  background-color: var(--azul-100);
}

.checkbox-item .checkbox-item-text {
  font-size: 16px;
}

.checkbox-item .checkbox-multiselect {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  margin-right: 12px;
  border: 1.5px solid var(--azul-600);
  transition: all 0.3s ease-in-out;
}

.checkbox-item.item-checked .checkbox-multiselect {
  background-color: var(--azul-600);
}
.checkbox-multiselect .check-icon {
  font-size: 12px;
  color: var(--branco);
  transform: scale(0);
  transition: all 0.5s ease-in-out;
}
.checkbox-item.item-checked .check-icon {
  transform: scale(1);
}
